import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '../../store'
import { generateApiKey } from '../../features/apiKeys/apiKeysSlice'
import WizardProgressBar from './WizardProgressBar'
import ConfirmationModal from '../../components/Modals/Confirmation/ConfirmationModal'
import BackIcon from '../../assets/images/arrow_chevron_left_rg.svg'
import { useTranslation } from 'react-i18next'
import WizardVehicleComponent from './WizardVehicleComponent'
import WizardLocationComponent from './WizardLocationComponent'
import WizardOverviewComponent from './WizardOverviewComponent'
import {
  getNextStep,
  getPreviousStep,
  isFirstStep,
  isLastStep,
  isStepValid,
  toWizardLocationConfiguration,
  toWizardVehicleConfiguration,
  updateWithLocationConfiguration,
  updateWithVehicleConfiguration,
  WizardConfiguration,
  WizardStep,
} from './WizardConfigurationUtil'
import './styles.css'
import { Button } from '../../components/Buttons/Button'
import { Provider } from '../../types/api.types'
import { useNavigate, useSearchParams } from 'react-router-dom'
import providerApi from '../../service/provider.api'
import { LoadingIndicator } from '../../components/LoadingIndicator/LoadingIndicator'
import { InfoBlock, InfoBlockProps } from '../../components/InfoBlock/InfoBlock'

const Wizard: React.FC = () => {
  const [provider, setProvider] = useState<Provider | null>(null)
  const [currentStep, setCurrentStep] = useState<WizardStep>('VEHICLE')
  const [maxStep, setMaxStep] = useState<WizardStep>(currentStep)
  const [isShowingConfirmation, setIsShowingConfirmation] = useState(false)
  const [configuration, setConfiguration] = useState<WizardConfiguration>({})
  const [failureState, setFailureState] = useState<InfoBlockProps>()
  const dispatch: AppDispatch = useDispatch()
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  //TODO: remove lowercase notation once all providers migrated
  const clientId = searchParams.get('clientId') || (searchParams.get('clientid') as string)
  const referenceId = searchParams.get('referenceId') || (searchParams.get('referenceid') as string)

  useEffect(() => {
    if (!clientId || !referenceId) {
      navigate('/', { replace: true })
      return
    }
    const fetchProvider = async () => {
      try {
        const provider = await providerApi.getProvider(clientId)
        setProvider(provider)
      } catch (error) {
        console.error('Failed to fetch provider', error)
        navigate('/', { replace: true })
      }
    }
    if (clientId !== provider?.clientId) {
      fetchProvider()
    }
  }, [navigate, searchParams, provider, clientId, referenceId])

  if (!provider) {
    return <LoadingIndicator />
  }
  const providerName = provider.name

  const handleNext = () => {
    const nextStep = getNextStep(currentStep)
    if (maxStep === currentStep) {
      setMaxStep(nextStep)
    }
    setCurrentStep(nextStep)
  }

  const handleBack = () => {
    setCurrentStep(getPreviousStep(currentStep))
  }

  const handleFinish = () => {
    setIsShowingConfirmation(true)
  }
  const handleGenerateApiKey = () => {
    const { vin, location } = configuration
    if (vin && location) {
      dispatch(generateApiKey({ vin, location, clientId, referenceId }))
    } else {
      console.error('Missing vin or location')
    }
  }

  return (
    <>
      {isShowingConfirmation && (
        <ConfirmationModal
          message={t('wizard.confirmation', { provider: providerName })}
          onConfirm={handleGenerateApiKey}
          onClose={() => setIsShowingConfirmation(false)}
        />
      )}
      <div className="wizard-container">
        {failureState ? (
          <InfoBlock title={failureState.title} description={failureState.description} />
        ) : (
          <>
            <WizardProgressBar
              configuration={configuration}
              currentStep={currentStep}
              maxStep={maxStep}
              setCurrentStep={(step) => setCurrentStep(step) /** TODO: validate that step can be reached */}
            />
            <div className="wizard-step-container" key={currentStep}>
              {currentStep === 'VEHICLE' && (
                <WizardVehicleComponent
                  provider={providerName}
                  configuration={toWizardVehicleConfiguration(configuration)}
                  setConfiguration={(stepConfig) =>
                    setConfiguration(updateWithVehicleConfiguration(configuration, stepConfig))
                  }
                  setFailureState={setFailureState}
                />
              )}

              {currentStep === 'LOCATION' && (
                <WizardLocationComponent
                  provider={providerName}
                  configuration={toWizardLocationConfiguration(configuration)}
                  setConfiguration={(stepConfig) =>
                    setConfiguration(updateWithLocationConfiguration(configuration, stepConfig))
                  }
                  setFailureState={setFailureState}
                />
              )}

              {currentStep === 'OVERVIEW' && (
                <WizardOverviewComponent
                  provider={providerName}
                  configuration={configuration}
                  onEdit={(step) => setCurrentStep(step)}
                />
              )}
            </div>
            <div className="wizard-navigation">
              {!isFirstStep(currentStep) && (
                <Button variant={'secondary'} onClick={handleBack}>
                  <img className="back-image" src={BackIcon} alt="Back" />
                </Button>
              )}
              {isLastStep(currentStep) ? (
                <Button variant={'primary'} style={{ flexGrow: 1 }} onClick={handleFinish}>
                  {t('wizard.button.confirm')}
                </Button>
              ) : (
                <Button
                  variant={'primary'}
                  disabled={!isStepValid(currentStep, configuration)}
                  style={{ flexGrow: 1 }}
                  onClick={handleNext}
                >
                  {t('wizard.button.continue')}
                </Button>
              )}
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default Wizard
